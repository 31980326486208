<template>
    <div class="page-invte" :class="isScrolled?'active':'unactive'">
        <van-nav-bar :border="false" :fixed="true" :placeholder="true" :left-text="$t('邀请朋友')"
            style="color: #FFFFFF !important;"
            left-arrow
            @click-left="onClickLeft"
            @click-right="onClickRight" :right-text="$t('代理模式')">
        </van-nav-bar>
        <div class="invte-main">
            <div class="invte-text">
                <div class="t">{{$t('邀请好友 共同赚币')}}</div>
                <div class="t2">{{$t('返佣升级，邀请好友共享最高40%交易返佣，和好友一起做老板吧')}}</div>
            </div>
            <div class="invte-one">
                <div class="wt-wx">
                    <div class="wx-pic" ref="shareQrcode" id="shareQrcode">
<!--                        <img src="../../assets/images/wx.png">-->
                    </div>
                    <div class="invte-code">{{$t('邀请码')}}：{{share_code}}</div>
                    <van-button class="btn-copy clipboardBtn" type="info" style="min-width: 100px" size="small"
                                data-clipboard-action="copy"
                                :data-clipboard-text="share_code" @click="copy('.clipboardBtn')">{{$t('复制')}}
                    </van-button>
                </div>
            </div>
            <div class="invte-two">
                <div class="text text-gray u-m-b10">{{$t('推荐链接')}}</div>
                    <div class="link-v u-m-b10">{{share_url}}</div>
                    <van-button class="btn-copy clipboardBtnUrl" type="info" style="min-width: 100px" size="small"
                                data-clipboard-action="copy"
                                :data-clipboard-text="share_url" @click="copy('.clipboardBtnUrl')">{{$t('复制')}}
                    </van-button>
            </div>
            <div class="invte-three">
                <div class="share-cell">
                    <div class="col">
                        <a target="_blank"
                           :href="`https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(share_url)}`">
                            <img src="../../assets/pictures/whatsapp.png" >
                            <div class="txt">{{$t('Line')}}</div>
                        </a>
                    </div>
                    <div class="col">
                        <a target="_blank"
                           :href="`sms:&body=${$t('Here is an opportunity to make money through e-commerce. See details here')}:${share_url}`">
                            <img src="../../assets/pictures/sms.png" >
                            <div class="txt">{{$t('SMS')}}</div>
                        </a>
                    </div>
                    <div class="col">
                        <a target="_blank"
                           :href="`mailto:?subject=${$t('asked you to make money')}&body=${$t('Here is a great opportunity to make money online')}:${share_url}`">
                            <img src="../../assets/pictures/youxiang.png" >
                        <div class="txt">{{$t('Email')}}</div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!--        <Foot :active="2"/>-->
    </div>
</template>
<script>
    import Foot from "@/components/Foot.vue";
    import Clipboard from "clipboard";
    import QRCode from "qrcodejs2";

    export default {
        components: {
            // Foot
        },
        data() {
            return {
                share_code: '',
                share_url: '',
                show: false,
                posterImg: '',
                qecode: '',
                loading: '',
                isScrolled: false
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            onClickRight() {
                this.$router.push({path: '/proxy'})
            },
            copy(val) {
                let clipboard = new Clipboard(val);
                clipboard.on("success", (e) => {
                    this.$toast.success(this.$t('复制成功'));
                    // 释放内存
                    clipboard.destroy();
                    console.log(this.share_url)
                });
                clipboard.on("error", (e) => {
                    // 不支持复制
                    this.$toast.fail(this.$t('复制失败'));
                    // 释放内存
                    clipboard.destroy();
                });
            },
            genQrcode() {
                let self = this;
                if (!this.share_url) {
                    return;
                }
                let url = this.share_url + "&t=" + new Date().getTime();
                if (this.qecode) {
                    this.qecode.clear();
                    this.qecode.makeCode(url);
                    this.share_url = url;
                    return;
                }
                this.qecode = new QRCode("shareQrcode", {
                    text: url,
                    typeNumber: 17,
                    width: 128,
                    height: 128,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.H,
                });
            },
            handleScroll() {
                if (window.pageYOffset >= 100) {
                    this.isScrolled = true
                } else {
                    this.isScrolled = false
                }
            }
        },
        mounted() {
            this.$axios.get(this.$api_url.v_login).then((res) => {
                if (res.data.code == 0) {
                    this.share_code = res.data.data.share_code;
                    this.share_url = res.data.data.share_url;
                    if(this.$route.query.lang){
                        this.share_url = this.share_url + '&lang=' +this.$route.query.lang;
                    }
                    this.genQrcode();
                }
            }).catch((err) => {
                this.$toast.fail(err.message);
            });
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleScroll)
        },
    }
</script>
<style>
    .page-invte{
        background:#252f46 url(../../assets/pictures/invte-banner.jpg) center top no-repeat;
        background-size: contain;
        min-height: 70vh;
        font-size: 14px;
        padding-top: 40px;
        text-align: center;
    }
    .page-invte.unactive .van-nav-bar{
        background-color: transparent!important;
    }
    .invte-text{
        margin-left: auto;
        margin-right: auto;
        max-width: 50%;
        min-height: 140px;
        margin-bottom: 10px;
    }
    .invte-text .t{
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 5px;
    }
    .invte-text .t2{
        font-size: 13px;
        opacity: .7;
    }
    .invte-one{
        position: relative;
        z-index: 10;
        border-radius: 20px;
        padding: 40px;
        background-color: #11181d;
    }
    .invte-two{
        position: relative;
        z-index: 5;
        margin-top: -30px;
        border-radius: 20px;
        padding:60px 40px 40px 40px;
        background-color: #1a2331;
    }
    .invte-three{
        padding: 40px 0;
    }
    .wx-pic{
        width: 130px;
        padding: 10px;
        background-color: #fff;
        margin:0 auto;
    }
    .invte-code{
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .share-cell{
        display: flex;
    }
    .share-cell .col{
        flex: 1;
    }
    .share-cell img{
        width: 36px;
        height: 36px;
    }
    .share-cell .txt{
        margin-top: 5px;
    }


</style>
